export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/charts`,
  async getOrderData(startDate, endDate, clientIds, shopIds, productGroupIds, promotionsString, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    return await $fetch(`${this.apiURL}/order?start_date=${startDate}&end_date=${endDate}&client_ids=${clientIds}&shop_ids=${shopIds}&product_group_ids=${productGroupIds}&promotions=${promotionsString}&comparisonType=${comparisonType}&comparison_start_date=${comparisonStartDate || ""}&comparison_end_date=${comparisonEndDate || ""}&comparison_client_ids=${comparisonClientIds || ""}&comparison_shop_ids=${comparisonShopIds || ""}`, { headers: { Authorization: useToken().value } });
  },
  async getUserData(startDate, endDate) {
    return await $fetch(`${this.apiURL}/user?start_date=${startDate}&end_date=${endDate}`, { headers: { Authorization: useToken().value } });
  },
  async getOrderDataByEventDate(selectedEvent, selectedPriceCategory, startDate, endDate, clientIds, shopIds, promoTicket, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "", comparisonEventsString = "", comparisonPriceCategoriesString = "", comparisonUsePromoTicket = "") {
    return await $fetch(`${this.apiURL}/order-by-event-date?selected_event_ids=${selectedEvent}&selected_price_category_ids=${selectedPriceCategory}&start_date=${startDate}&end_date=${endDate}&client_ids=${clientIds}&shop_id=${shopIds}&promo_ticket=${promoTicket}&comparisonType=${comparisonType}&comparison_start_date=${comparisonStartDate || ""}&comparison_end_date=${comparisonEndDate || ""}&comparison_client_ids=${comparisonClientIds || ""}&comparison_shop_ids=${comparisonShopIds || ""}&comparison_selected_event_ids=${comparisonEventsString || ""}&comparison_selected_price_category_ids=${comparisonPriceCategoriesString || ""}&comparison_promo_ticket=${comparisonUsePromoTicket}`, { headers: { Authorization: useToken().value } });
  },
  async getTicketsInformation(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/tickets-information`,
      {
        headers: { Authorization: useToken().value },
        params: {
          "start_date": startDate,
          "end_date": endDate,
          "client_ids[]": clients.map(client => client.id),
          "shop_ids[]": shops.map(shop => shop.id),
          "product_group_ids[]": productGroupIds,
          "promotions[]": promotionIds,
          "comparisonType[]": comparisonType,
          "comparison_start_date": comparisonStartDate,
          "comparison_end_date": comparisonEndDate,
          "comparison_client_ids[]": comparisonClientIds,
          "comparison_shop_ids[]": comparisonShopIds,
        },
      });
  },
  async getTicketsSoldChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/tickets-sold`,
      {
        headers: { Authorization: useToken().value },
        params: {
          "start_date": startDate,
          "end_date": endDate,
          "client_ids[]": clients.map(client => client.id),
          "shop_ids[]": shops.map(shop => shop.id),
          "product_group_ids[]": productGroupIds,
          "promotions[]": promotionIds,
          "comparisonType[]": comparisonType,
          "comparison_start_date": comparisonStartDate,
          "comparison_end_date": comparisonEndDate,
          "comparison_client_ids[]": comparisonClientIds,
          "comparison_shop_ids[]": comparisonShopIds,
        },
      });
  },
  async getTicketsRevenueChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/tickets-revenue`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getTicketsSoldByCategoriesPieChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/tickets-sold-by-categories-pie`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getTicketsSoldByShopsPieChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/tickets-sold-by-shops-pie`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getFoodUpgradesSoldByShopsPieChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/food-upgrades-sold-by-shops-pie`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getVouchersSoldByShopsPieChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/vouchers-sold-by-shops-pie`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getTicketsPriceAvgChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/tickets-avg-price`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getTicketsSoldByMonth(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/tickets-sold-by-month`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getPromoTicketsUsed(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/promo-tickets-used`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getStornoTickets(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/storno-tickets`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getMenusSold(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/menu-sold`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getMenusRevenueChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/menu-revenue`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getMenusPriceAvgChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/menu-avg-price`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getMenusQuoteChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/menu-quote`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getVouchersSoldChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/vouchers-sold`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getVouchersRevenueChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/vouchers-revenue`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getVouchersPriceAvgChart(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/vouchers-avg-price`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getFoodUpgradesInformation(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/food-upgrades-information`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
  async getVouchersInformation(startDate, endDate, clients, shops, productGroupIds, promotionIds, comparisonType = "", comparisonStartDate = "", comparisonEndDate = "", comparisonClientIds = "", comparisonShopIds = "") {
    if (clients.length === 0) return { data: [] };
    if (shops.length === 0) return { data: [] };
    return await $fetch(`${this.apiURL}/vouchers-information`, {
      headers: { Authorization: useToken().value },
      params: {
        "start_date": startDate,
        "end_date": endDate,
        "client_ids[]": clients.map(client => client.id),
        "shop_ids[]": shops.map(shop => shop.id),
        "product_group_ids[]": productGroupIds,
        "promotions[]": promotionIds,
        "comparisonType[]": comparisonType,
        "comparison_start_date": comparisonStartDate,
        "comparison_end_date": comparisonEndDate,
        "comparison_client_ids[]": comparisonClientIds,
        "comparison_shop_ids[]": comparisonShopIds,
      },
    });
  },
});
