export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/orders`,
  async getOrders() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },
  async getOrder(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getOrderSummary(id) {
    return await $fetch(`${this.apiURL}/summary/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getOrderItem(id) {
    return await $fetch(`${this.apiURL}/order-item/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getTaxTable(id, isSummary) {
    return await $fetch(`${this.apiURL}/${id}/tax-table?is_summary=${isSummary}`, { headers: { Authorization: useToken().value } });
  },
  async createOrder(shopId, clientId, userId, fiskalyClientId) {
    return await $fetch(this.apiURL, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { shop_id: shopId, client_id: clientId, user_id: userId, fiskaly_client_id: fiskalyClientId },
    });
  },
  async updateOrder(order) {
    return await $fetch(this.apiURL, {
      method: "PUT",
      headers: { Authorization: useToken().value },
      body: { ...order },
    });
  },
  async deleteOrder(order) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: order.id },
    });
  },
  async clearOrder(order) {
    return await $fetch(`${this.apiURL}/${order.id}/clear`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async addOrderItem(order, item) {
    return await $fetch(`${this.apiURL}/${order.id}/add-order-item`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: item,
    });
  },
  async removeProductItem(order, item) {
    return await $fetch(`${this.apiURL}/${order.id}/remove-product-item`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: item,
    });
  },
  async removeOrderItem(order, orderItemId) {
    return await $fetch(`${this.apiURL}/${order.id}/remove-order-item`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { order_item_id: orderItemId },
    });
  },
  async stornoOrderItem(order, orderItemId) {
    return await $fetch(`${this.apiURL}/${order.id}/storno-order-item`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { order_item_id: orderItemId },
    });
  },
  async updateOrderItem(order, orderItem, updateAll) {
    return await $fetch(`${this.apiURL}/${order.id}/update-order-item`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...orderItem, update_all: updateAll },
    });
  },
  async saveComment(order, comment) {
    return await $fetch(`${this.apiURL}/${order.id}/save-comment`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...comment },
    });
  },
  async deleteComment(order, comment) {
    return await $fetch(`${this.apiURL}/${order.id}/delete-comment`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id: comment.id },
    });
  },
  async publishOrder(order) {
    return await $fetch(`${this.apiURL}/${order.id}/publish`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...order },
    });
  },
  async saveReservation(order) {
    return await $fetch(`${this.apiURL}/${order.id}/reservation`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async storno(order, reason, info) {
    return await $fetch(`${this.apiURL}/${order.id}/storno`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { cancel_reason: reason, cancel_info: info },
    });
  },
  async extend(order) {
    return await $fetch(`${this.apiURL}/${order.id}/extend`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async getEventDates(order, fromDate, offset, length, eventIds) {
    return await $fetch(`${this.apiURL}/${order.id}/get-event-dates`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { offset, length, from_date: fromDate, event_ids: eventIds },
    });
  },
  async getSalesProducts(order) {
    return await $fetch(`${this.apiURL}/${order.id}/get-sales-products`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async getGifts(order, priceListId, priceCategoryId, discountLevelId) {
    return await $fetch(`${this.apiURL}/${order.id}/get-gifts`, {
      method: "GET",
      headers: { Authorization: useToken().value },
      params: { price_list_id: priceListId, price_category_id: priceCategoryId, discount_level_id: discountLevelId },
    });
  },
  async usePromotion(order, promotion, orderItems) {
    return await $fetch(`${this.apiURL}/${order.id}/use-promotion`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { promotion_id: promotion.id, order_items: orderItems },
    });
  },
  async useCode(order, code, orderItems) {
    return await $fetch(`${this.apiURL}/${order.id}/use-promotion`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { code, order_items: orderItems },
    });
  },
  async useExternCode(order, code) {
    return await $fetch(`${this.apiURL}/${order.id}/use-extern-voucher`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { code },
    });
  },
  async removeOrderItemsPromotion(order, orderItems) {
    return await $fetch(`${this.apiURL}/${order.id}/remove-promotion`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { order_items: orderItems },
    });
  },
  async removePromotionFromOrder(order) {
    return await $fetch(`${this.apiURL}/${order.id}/remove-promotion-order`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async updatePayment(order, paymentId) {
    return await $fetch(`${this.apiURL}/${order.id}/update-payment`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { payment_id: paymentId },
    });
  },
  async updateShipping(order, shippingId) {
    return await $fetch(`${this.apiURL}/${order.id}/update-shipping`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { shipping_id: shippingId },
    });
  },
  async updateUserPayment(order, userPaymentId) {
    return await $fetch(`${this.apiURL}/${order.id}/update-user-payment`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { user_payment_id: userPaymentId },
    });
  },
  async updateAddress(order) {
    return await $fetch(`${this.apiURL}/${order.id}/update-address`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...order },
    });
  },
  async updateUser(order, userId) {
    return await $fetch(`${this.apiURL}/${order.id}/update-user`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { user_id: userId },
    });
  },
  async changeDiscountLevel(order, prices, productId, eventDateId, eventDateProductId) {
    return await $fetch(`${this.apiURL}/${order.id}/change-discount-level`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { prices, product_id: productId, event_date_id: eventDateId, event_date_product_id: eventDateProductId },
    });
  },
  async getUpgrades(orderId, eventDateId) {
    return await $fetch(`${this.apiURL}/${orderId}/get-upgrades/${eventDateId}`, {
      headers: { Authorization: useToken().value },
    });
  },
  async getUpgradesByOrderItem(orderItemId) {
    return await $fetch(`${this.apiURL}/order-items/${orderItemId}/get-upgrades`, {
      headers: { Authorization: useToken().value },
    });
  },
  async getUpgradesPreview(orderId, eventDateId) {
    return await $fetch(`${this.apiURL}/${orderId}/get-upgrades/${eventDateId}?show_all=1`, {
      headers: { Authorization: useToken().value },
    });
  },
  async getTickets(orderId, eventDateId) {
    return await $fetch(`${this.apiURL}/${orderId}/get-tickets/${eventDateId}`, {
      headers: { Authorization: useToken().value },
    });
  },
  async getTicket(orderId, eventDateProductId, productId) {
    return await $fetch(`${this.apiURL}/${orderId}/get-ticket/${eventDateProductId}`, {
      headers: { Authorization: useToken().value },
      params: { product_id: productId },
    });
  },
  async generateDocuments(order) {
    return await $fetch(`${this.apiURL}/${order.id}/generate-documents`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async removePaymentCosts(order) {
    return await $fetch(`${this.apiURL}/${order.id}/remove-payment-costs`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
  async removeShippingCosts(order) {
    return await $fetch(`${this.apiURL}/${order.id}/remove-shipping-costs`, {
      method: "POST",
      headers: { Authorization: useToken().value },
    });
  },
});
